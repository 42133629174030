<template>
  <v-card>
<v-container>
<v-row>
	<h2 class="mt-6 ml-6">Productividad {{productivity.TypeAreName }} - {{productivity.TypeTurnName}} </h2>
						<v-spacer> </v-spacer>
						<v-btn
							class="mt-3 mr-3"
							small
							fab
							color="default"
							><i style="font-size:20px;" color="error" class="fas fa-file-pdf"></i
						></v-btn>
						<v-btn
							@click="$emit('closeshowProductivity')"
							class="mt-3 mr-3"
							small
							fab
							color="error"
							><i style="font-size:16px;" class="fas fa-times"></i
						></v-btn>
					</v-row>

					<v-row>



             <v-col lg="6" class="pt-0">
                         <v-row>
                            <h4 class="mt-6 ml-6"> Horario de trabajo     </h4>
                                <v-spacer> </v-spacer>
                                
                        </v-row>

                        <v-row>
                            <v-col class="pb-0">
                                <v-divider></v-divider>
                            </v-col>
                        </v-row>
                        <v-list>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-btn color="info" fab><i class="fas fa-clock" style="font-size:18px"></i></v-btn>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-list-item-title ><b>{{itemWorkSchedule.TrnDate}}</b></v-list-item-title>
                                    <v-list-item-subtitle >Fecha</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-btn color="warning" fab><i style="font-size:18px" class="fas fa-user-clock"></i></v-btn>
                                </v-list-item-avatar>
                                
                                <v-list-item-content>
                                    <v-list-item-title ><b>{{itemWorkSchedule.TypeTurnName}}</b> </v-list-item-title>
                                    <v-list-item-subtitle >Turno</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            

                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-btn color="warning" fab><i style="font-size:18px" class="fas fa-user-clock"></i></v-btn>
                                </v-list-item-avatar>
                                
                                <v-list-item-content>
                                    <v-list-item-title ><b>{{itemWorkSchedule.TrnDateBegin}} - {{itemWorkSchedule.TrnDateEnd}} = {{itemWorkSchedule.TrnHoursWorkedAll}}</b></v-list-item-title>
                                    <v-list-item-subtitle >Hora Inicio / Hora Fin</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-btn color="warning" fab><i style="font-size:18px" class="fas fa-user-clock"></i></v-btn>
                                </v-list-item-avatar>
                                
                                <v-list-item-content>
                                    <v-list-item-title ><b>{{itemWorkSchedule.TrnHoursWorkedDead}}</b></v-list-item-title>
                                    <v-list-item-subtitle >Horas muertas</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-btn color="warning" fab><i style="font-size:18px" class="fas fa-user-clock"></i></v-btn>
                                </v-list-item-avatar>
                                
                                <v-list-item-content>
                                    <v-list-item-title ><b>{{itemWorkSchedule.TrnHoursWorkedReal}} </b></v-list-item-title>
                                    <v-list-item-subtitle >Horas Trabajadas</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>


                            	   <v-list-item>
        <v-list-item-avatar>
     <v-btn color="warning" fab><i class="fas fa-percent" style="font-size:18px"></i></v-btn>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title >Utilizacion</v-list-item-title>
     <v-list-item-subtitle >{{productivity.PUtilization}}</v-list-item-subtitle>  </v-list-item-content>   </v-list-item-content>
      </v-list-item>
	   <v-list-item>
        <v-list-item-avatar>
     <v-btn color="warning" fab><i class="fas fa-percent" style="font-size:18px"></i></v-btn>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title >Eficiencia</v-list-item-title>
          <v-list-item-subtitle >{{productivity.PEfficiency}}</v-list-item-subtitle>  </v-list-item-content>
      </v-list-item>




                               <v-list-item>
        <v-list-item-avatar>
<v-btn color="success" fab><i style="font-size:18px" class="fas fa-vector-square"></i></v-btn>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title >Zonas</v-list-item-title>
          <v-list-item-subtitle >
		  	  <v-data-table
		  dense :items="productivity.Zones"
		   :headers="[{text:'Zona',value:'ZonName'},{text:'Kilogramos',value:'WbbGrossWeight'},{text:'Jabas/Bines',value:'AmountJabasWeigh'}]">
		  </v-data-table>
		  </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

                            
                        </v-list>
                    </v-col>                    

                    <v-col lg="6" class="pt-0">
                         <v-row>
                            <h4 class="mt-6 ml-6"> Cantidad de Personal   </h4>
                                <v-spacer> </v-spacer>
                                
                        </v-row>

                        <v-row>
                            <v-col class="pb-0">
                                <v-divider></v-divider>
                            </v-col>
                        </v-row>
                        <v-list>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-btn color="info" fab><i class="fas fa-clock" style="font-size:18px"></i></v-btn>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-list-item-title ><b>{{itemWorkers.AgdWorkerMan}}</b></v-list-item-title>
                                    <v-list-item-subtitle >Varones MOD</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-btn color="warning" fab><i style="font-size:18px" class="fas fa-user-clock"></i></v-btn>
                                </v-list-item-avatar>
                                
                                <v-list-item-content>
                                    <v-list-item-title ><b>{{itemWorkers.AgdWorkerWoman}} </b></v-list-item-title>
                                    <v-list-item-subtitle >Mujeres MOD</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-btn color="warning" fab><i style="font-size:18px" class="fas fa-user-clock"></i></v-btn>
                                </v-list-item-avatar>
                                
                                <v-list-item-content>
                                    <v-list-item-title ><b>{{itemWorkers.AgdWorkerAll}}</b></v-list-item-title>
                                    <v-list-item-subtitle >Total MOD</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                           	       <v-list-item>
        <v-list-item-avatar>
<v-btn color="success" fab><i style="font-size:18px" class="fas fa-weight"></i></v-btn>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title >Kilogramos / Hora - Expectativa Razonable</v-list-item-title>
          <v-list-item-subtitle >{{productivity.QuantityxHR}} - {{productivity.ExpectationxHR}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
	       <v-list-item>
        <v-list-item-avatar>
<v-btn color="success" fab><i style="font-size:18px" class="fas fa-box"></i></v-btn>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title >Jabas / Hora</v-list-item-title>
          <v-list-item-subtitle >{{productivity.BoxesxHR}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      	   <v-list-item>
        <v-list-item-avatar>
     <v-btn color="warning" fab><i class="fas fa-percent" style="font-size:18px"></i></v-btn>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title >Productividad</v-list-item-title>
            <v-list-item-subtitle >{{productivity.pProductivity}}</v-list-item-subtitle></v-list-item-content>
      </v-list-item>

                            
	       <v-list-item>
        <v-list-item-avatar>
<v-btn color="orange" dark fab><i style="font-size:18px" class="fas fa-leaf"></i></v-btn>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title >Variedades</v-list-item-title>
          <v-list-item-subtitle >
		  <v-data-table
		  dense :items="productivity.Varieties"
		   :headers="[{text:'Variedad',value:'VrtName'},{text:'Kilogramos',value:'WbbGrossWeight'},{text:'Jabas/Bines',value:'AmountJabasWeigh'}]">
		  </v-data-table>
		  </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

                            
                        </v-list>
                    </v-col>




<!-- <v-col class="pb-0"><v-divider></v-divider>
</v-col></v-row>
<v-row>

<v-col lg="6" class="pt-0">
<v-list>
 <v-list-item>
        <v-list-item-avatar>
     <v-btn color="info" fab><i class="fas fa-clock" style="font-size:18px"></i></v-btn>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title >Hora de Inicio - Hora de Fin</v-list-item-title>
          <v-list-item-subtitle >{{this.$fun.formatDateView(productivity.TnrDateBegin) }} {{this.$fun.formatTimeView(productivity.TnrDateBegin) }}
		  - {{this.$fun.formatDateView(productivity.TnrDateEnd)}} {{this.$fun.formatTimeView(productivity.TnrDateEnd)}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
	   <v-list-item>
        <v-list-item-avatar>
<v-btn color="warning" fab><i style="font-size:18px" class="fas fa-user-clock"></i></v-btn>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title >Numero de Trabajadores</v-list-item-title>
          <v-list-item-subtitle >{{productivity.QuantityWorker}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
	     <v-list-item>
        <v-list-item-avatar>
<v-btn color="success" fab><i style="font-size:18px" class="fas fa-vial"></i></v-btn>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title >Grados BRIX</v-list-item-title>
          <v-list-item-subtitle >{{productivity.BRIX}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
	       <v-list-item>
        <v-list-item-avatar>
<v-btn color="success" fab><i style="font-size:18px" class="fas fa-weight"></i></v-btn>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title >Kilogramos / Hora - Expectativa Razonable</v-list-item-title>
          <v-list-item-subtitle >{{productivity.QuantityxHR}} - {{productivity.ExpectationxHR}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
	       <v-list-item>
        <v-list-item-avatar>
<v-btn color="success" fab><i style="font-size:18px" class="fas fa-box"></i></v-btn>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title >Jabas / Hora</v-list-item-title>
          <v-list-item-subtitle >{{productivity.BoxesxHR}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
	       <v-list-item>
        <v-list-item-avatar>
<v-btn color="orange" dark fab><i style="font-size:18px" class="fas fa-leaf"></i></v-btn>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title >Variedades</v-list-item-title>
          <v-list-item-subtitle >
		  <v-data-table
		  dense :items="productivity.Varieties"
		   :headers="[{text:'Variedad',value:'VrtName'},{text:'Kilogramos',value:'WbbGrossWeight'},{text:'Jabas/Bines',value:'AmountJabasWeigh'}]">
		  </v-data-table>
		  </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
	     
    </v-list></v-col>
<v-col class="pt-0">
<v-list>
  <v-list-item>
        <v-list-item-avatar>
     <v-btn color="info" fab><i class="fas fa-clock" style="font-size:18px"></i></v-btn>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title >Horas Disponibles</v-list-item-title>
          <v-list-item-subtitle >{{productivity.Hours}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
	  <v-list-item>
        <v-list-item-avatar>
     <v-btn color="info" fab><i class="fas fa-clock" style="font-size:18px"></i></v-btn>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title >Horas Trabajadas</v-list-item-title>
          <v-list-item-subtitle >{{productivity.HoursWorked}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
	   <v-list-item>
        <v-list-item-avatar>
     <v-btn color="warning" fab><i class="fas fa-percent" style="font-size:18px"></i></v-btn>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title >Utilizacion</v-list-item-title>
     <v-list-item-subtitle >{{productivity.PUtilization}}</v-list-item-subtitle>  </v-list-item-content>   </v-list-item-content>
      </v-list-item>
	   <v-list-item>
        <v-list-item-avatar>
     <v-btn color="warning" fab><i class="fas fa-percent" style="font-size:18px"></i></v-btn>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title >Eficiencia</v-list-item-title>
          <v-list-item-subtitle >{{productivity.PEfficiency}}</v-list-item-subtitle>  </v-list-item-content>
      </v-list-item>
	   <v-list-item>
        <v-list-item-avatar>
     <v-btn color="warning" fab><i class="fas fa-percent" style="font-size:18px"></i></v-btn>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title >Productividad</v-list-item-title>
            <v-list-item-subtitle >{{productivity.pProductivity}}</v-list-item-subtitle></v-list-item-content>
      </v-list-item>
	
   <v-list-item>
        <v-list-item-avatar>
<v-btn color="success" fab><i style="font-size:18px" class="fas fa-vector-square"></i></v-btn>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title >Zonas</v-list-item-title>
          <v-list-item-subtitle >
		  	  <v-data-table
		  dense :items="productivity.Zones"
		   :headers="[{text:'Zona',value:'ZonName'},{text:'Kilogramos',value:'WbbGrossWeight'},{text:'Jabas/Bines',value:'AmountJabasWeigh'}]">
		  </v-data-table>
		  </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
</v-list>
</v-col> -->




                      <v-col lg="12" class="pt-0">
                         <v-row class="pt-3 pr-3 pl-3">
                            <h4 class="mt-6 ml-6"> Flujo de camiones </h4>
                            <v-spacer></v-spacer> 
                            <v-btn style="margin-top:15px" rounded color="" fab x-small @click="editPlate()">
                             
                             <i class="fas fa-plus"></i>
                            </v-btn>                           
                        </v-row>
                        <v-row>
                            <v-col class="pb-0">
                                <v-divider></v-divider>
                            </v-col>
                        </v-row>
                        <v-col cols="12" class="mt-5">
                            <v-data-table
                                dense
                                :items="productivity.PrfTurnReceptionFreshTruckCE"
                                :headers="PrfTurnReceptionFreshTruckHeader">

                            <!-- <template v-slot:item.RftPlate="{ item }">
                              <s-text
                                @input="inputPlate(item)"
                                v-model="item.RftPlate"
                                ></s-text>


                            </template> -->
                             <template v-slot:item.RftPlate="props">
                            <s-select-transport
                              :value="props.item.RftPlate"
                              full
                              @input="inputPlate(props.item)"
                              v-model="props.item.RftPlate"
                            >
                            </s-select-transport>
                          </template>

                            </v-data-table>
                            
                            
                        </v-col>
                        

                    </v-col>

                    <v-col lg="12" class="pt-0">
                         <v-row>
                            <h4 class="mt-6 ml-6"> Paradas </h4>
                                <v-spacer> </v-spacer>
                                
                        </v-row>

                        <v-row>
                            <v-col class="pb-0">
                                <v-divider></v-divider>
                            </v-col>
                        </v-row>

                        <v-col cols="12" class="mt-5">
                            <v-data-table
                                dense
                                :items="itemTurnReceptionFreshUnloadStop"
                                :headers="PrfTurnReceptionFreshUnloadStopHeader"
                            >
                            <template v-slot:item.Actions="{ item }">
                              <v-btn
                                @click="clickDeleteTurStop(item)"
                                x-small
                                color="error"
                                elevation="0"
                                block>
                                <v-icon style="font-size: 16px !important"
                                  >fas fa-times</v-icon>
                              </v-btn>
                            </template>
                            </v-data-table>
                            
                            
                        </v-col>
                        

                    </v-col>

                    <v-col lg="12" class="pt-0">
                         <v-row>
                            <h4 class="mt-6 ml-6"> Personal Transferido     </h4>
                                <v-spacer> </v-spacer>
                                
                        </v-row>

                        <v-row>
                            <v-col class="pb-0">
                                <v-divider></v-divider>
                            </v-col>
                        </v-row>

                        <v-col cols="12" class="mt-5">
                            <v-data-table
                                dense
                                :items="itemTransfer"
                                :headers="itemTransferHeader"
                                hide-default-footer
                            >

                            </v-data-table>
                            
                            
                        </v-col>
                       
                    </v-col>




</v-row>

</v-container>
</v-card>
</template>

<script>

import _sReceptionFreshUnload from "@/services/FreshProduction/ReceptionFreshUnloadService";
import SSelectTransport from "@/components/HarvestSchedule/SSelectTransport";
export default {
  props: {
    productivity: null,
  },
  components:{SSelectTransport},
  data: () => {
    return {
      itemWorkSchedule: {},
      itemUpdate : null,
      itemWorkers: {},
      DeletePrfTurnReceptionFreshUnloadStopCE : [],
      itemTurnReceptionFreshUnloadStop : [],
      PrfTurnReceptionFreshTruckHeader: [
        { text: "Fecha", value: "RftDate", width: 100 },
        { text: "´Placa", value: "RftPlate", width: 100 },
        { text: "QTD", value: "QTD", width: 100 },
        { text: "N° Jabas", value: "RftQuantityJabas", width: 100 },
        { text: "H. Inicio Descarga", value: "RftDateBegin", width: 100 },
        { text: "H. Fin Descarga", value: "RftDateEnd", width: 100 },
        { text: "Minutos de Descarga", value: "RftHour", width: 100 },
        /* { text: "Hora de Descarga", value: "RftHour", width: 100 }, */
        { text: "Año", value: "RftYear", width: 100 },
        { text: "Semana", value: "RftWeek", width: 100 },
        { text: "Dia", value: "RftDay", width: 100 },
        /* { text: "Intervalo Hora", value: "RftIntervalHour", width: 100 }, */
        { text: "N° de Cuadrilla", value: "RftGang", width: 150 },
        { text: "Cant. de Personas", value: "RftWorker", width: 100 },
      ],

      PrfTurnReceptionFreshUnloadStopHeader: [
        { text: "Acciones", value: "Actions" },
        { text: "Semana", value: "RfsWeek" },
        { text: "Fecha", value: "RfsDate" },
        { text: "Día", value: "RfsDay" },
        { text: "Desde", value: "RfsDateBegin" },
        { text: "Hasta", value: "RfsDateEnd" },
        { text: "Duración", value: "RfsDuartion" },
        { text: "Motivo Parada", value: "RstName" },
        { text: "Cuadrilla", value: "RftGang" },
        { text: "Cant. Personas", value: "RftWorker" },
      ],

      itemTransfer: [],
      itemTransferHeader: [
        { text: "Persona", value: "AgdWorkerName" },
        { text: "Docuemento", value: "PrsDocumentNumber" },
        { text: "Hora Inicio", value: "AgdBeginDate" },
        { text: "Hora Fin", value: "AgdEndDate" },
        { text: "H. Trabajadas", value: "AgdHoursWorkedReal" },
      ],
    };
  },
  methods: {

    inputPlate(item){
      this.itemUpdate = item;
      console.log('input modificado', item);
    },


    editPlate() {

      if(this.itemUpdate == null){
        this.$fun.alert("Debe modificar la placa para actualizar","warning");
        return;
      }

      console.log('datos flujo de camiones actualizados', this.productivity.PrfTurnReceptionFreshTruckCE);
      _sReceptionFreshUnload.save(this.itemUpdate, this.$fun.getUserID())
      .then((r)=>{
        if(r.status == 200){
          this.$fun.alert('registros actualizados correctamente', "success");
        }
      });


    },

    clickDeleteTurStop(item) {

      console.log('turno eliminado', item)
       _sReceptionFreshUnload.saveStop(item, this.$fun.getUserID())
        .then((r)=>{
          if(r.status == 200){
            this.$fun.alert('registro eliminado correctamente', "success");
            this.$emit("closeshowProductivity");
          }
      });
    },

    orderTurStop () {
      let i = 0;
      this.itemTurnReceptionFreshUnloadStop.map((e)=> {
        i++;
        e.Line = i;
      })
    }

  },
  created() {

    console.log('verificando el ID',this.productivity.PrfTurnReceptionFreshUnloadStopCE)

    if (this.productivity.PrfTurnPackingEurHeaderCE.length > 0) {
      this.productivity.PrfTurnPackingEurHeaderCE.forEach((element) => {
        element.TrnDate = this.$moment(element.TrnDate).format(
          this.$const.FormatDateDB
        );
        element.TrnDateBegin = this.$moment(element.TrnDateBegin).format(
          this.$const.FormatTimeDB
        );
        element.TrnDateEnd = this.$moment(element.TrnDateEnd).format(
          this.$const.FormatTimeDB
        );
      });

      this.itemWorkSchedule = this.productivity.PrfTurnPackingEurHeaderCE[0];
    }

    if (this.productivity.PrfTurnPackingEurWorkerCE.length > 0) {
      this.itemWorkers = this.productivity.PrfTurnPackingEurWorkerCE[0];
    }
  
    if (this.productivity.PrfTurnReceptionFreshTruckCE.length > 0) {
      console.log('datos flujo de camiones',this.productivity.PrfTurnReceptionFreshTruckCE );
      this.productivity.PrfTurnReceptionFreshTruckCE.forEach((element) => {
        element.RftDate = this.$moment(element.RftDate).format(
          this.$const.FormatDateDB
        );
      });
    }

    if (this.productivity.PrfTurnReceptionFreshUnloadStopCE.length > 0) {

      this.itemTurnReceptionFreshUnloadStop = this.productivity.PrfTurnReceptionFreshUnloadStopCE;

      this.itemTurnReceptionFreshUnloadStop.forEach((element, index) => {
        element.Line = index + 1;
        element.RfsDate = this.$moment(element.RfsDate).format(
          this.$const.FormatDateDB
        );
      });

      console.log('datos modificados', this.itemTurnReceptionFreshUnloadStop)

      // this.productivity.PrfTurnReceptionFreshUnloadStopCE.forEach((element) => {
      //   element.RfsDate = this.$moment(element.RfsDate).format(
      //     this.$const.FormatDateDB
      //   );
      // });
    }

    if (this.itemsProductivity.PrfTurnWorkerTransferCE.length > 0) {
      this.itemsProductivity.PrfTurnWorkerTransferCE.forEach((element) => {
        element.AgdBeginDate = this.$moment(element.AgdBeginDate).format(
          this.$const.FormatDateTimeView
        );
        element.AgdEndDate = this.$moment(element.AgdEndDate).format(
          this.$const.FormatDateTimeDB
        );
      });

      this.itemTransfer = this.itemsProductivity.PrfTurnWorkerTransferCE;
    }
  },
};
</script>

<style>
</style>