<template>
    <div>

        <v-card>
            <v-container>
                <v-row>
                    <h3 class="mt-3 ml-6"> REPORTE EMPAQUE EUROPA     </h3>
                        <v-spacer> </v-spacer>
						<v-btn
							class="mt-3 mr-3"
							small
							fab
							color="default"
							><i style="font-size:20px;" color="error" class="fas fa-file-pdf"></i
						></v-btn>
						<v-btn
							@click="$emit('close')"
							class="mt-3 mr-3"
							small
							fab
							color="error"
							><i style="font-size:16px;" class="fas fa-times"></i
						></v-btn>
                        
                </v-row>

				<v-row>
                    <v-col class="pb-0">
                        <v-divider></v-divider>
                    </v-col>
                </v-row>

                <v-row>

                    <v-col lg="6" class="pt-0">
                         <v-row>
                            <h4 class="mt-6 ml-6"> Horario de trabajo     </h4>
                                <v-spacer> </v-spacer>
                                
                        </v-row>

                        <v-row>
                            <v-col class="pb-0">
                                <v-divider></v-divider>
                            </v-col>
                        </v-row>
                        <v-list>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-btn color="info" fab><i class="fas fa-clock" style="font-size:18px"></i></v-btn>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-list-item-title ><b>{{itemWorkSchedule.TrnDate}}</b></v-list-item-title>
                                    <v-list-item-subtitle >Fecha</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-btn color="warning" fab><i style="font-size:18px" class="fas fa-user-clock"></i></v-btn>
                                </v-list-item-avatar>
                                
                                <v-list-item-content>
                                    <v-list-item-title ><b>{{itemWorkSchedule.TypeTurnName}}</b> </v-list-item-title>
                                    <v-list-item-subtitle >Turno</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            

                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-btn color="warning" fab><i style="font-size:18px" class="fas fa-user-clock"></i></v-btn>
                                </v-list-item-avatar>
                                
                                <v-list-item-content>
                                    <v-list-item-title ><b>{{itemWorkSchedule.TrnDateBegin}} - {{itemWorkSchedule.TrnDateEnd}} = {{itemWorkSchedule.TrnHoursWorkedAll}}</b></v-list-item-title>
                                    <v-list-item-subtitle >Hora Inicio / Hora Fin</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-btn color="warning" fab><i style="font-size:18px" class="fas fa-user-clock"></i></v-btn>
                                </v-list-item-avatar>
                                
                                <v-list-item-content>
                                    <v-list-item-title ><b>{{itemWorkSchedule.TrnHoursWorkedDead}}</b></v-list-item-title>
                                    <v-list-item-subtitle >Horas muertas</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-btn color="warning" fab><i style="font-size:18px" class="fas fa-user-clock"></i></v-btn>
                                </v-list-item-avatar>
                                
                                <v-list-item-content>
                                    <v-list-item-title ><b>{{itemWorkSchedule.TrnHoursWorkedReal}} </b></v-list-item-title>
                                    <v-list-item-subtitle >Horas Trabajadas</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            
                        </v-list>
                    </v-col>                    

                    <v-col lg="6" class="pt-0">
                         <v-row>
                            <h4 class="mt-6 ml-6"> Cantidad de Personal   </h4>
                                <v-spacer> </v-spacer>
                                
                        </v-row>

                        <v-row>
                            <v-col class="pb-0">
                                <v-divider></v-divider>
                            </v-col>
                        </v-row>
                        <v-list>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-btn color="info" fab><i class="fas fa-clock" style="font-size:18px"></i></v-btn>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-list-item-title ><b>{{itemWorkers.AgdWorkerMan}}</b></v-list-item-title>
                                    <v-list-item-subtitle >Varones MOD</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-btn color="warning" fab><i style="font-size:18px" class="fas fa-user-clock"></i></v-btn>
                                </v-list-item-avatar>
                                
                                <v-list-item-content>
                                    <v-list-item-title ><b>{{itemWorkers.AgdWorkerWoman}} </b></v-list-item-title>
                                    <v-list-item-subtitle >Mujeres MOD</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            

                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-btn color="warning" fab><i style="font-size:18px" class="fas fa-user-clock"></i></v-btn>
                                </v-list-item-avatar>
                                
                                <v-list-item-content>
                                    <v-list-item-title ><b>{{itemWorkers.AgdWorkerAll}}</b></v-list-item-title>
                                    <v-list-item-subtitle >Total MOD</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            
                        </v-list>
                    </v-col>

                    <v-col lg="12" class="pt-0">
                         <v-row>
                            <h4 class="mt-6 ml-6"> Abastecimiento </h4>
                                <v-spacer> </v-spacer>
                                
                        </v-row>

                        <v-row>
                            <v-col class="pb-0">
                                <v-divider></v-divider>
                            </v-col>
                        </v-row>

                        <v-col cols="12" class="mt-5">
                            <v-data-table
                                dense
                                :items="itemSupplying"
                                :headers="itemSupplyingHeader"
                                hide-default-footer
                            >

                            </v-data-table>
                            
                            
                        </v-col>
                        <v-row>
                            <v-col cols="6">
                                <b>Total Jabas: {{sumAbasJabas}}</b>
                            </v-col>
                            <v-col cols="6">
                                <b>Total KG: {{sumAbasKG}}</b>
                            </v-col>
                        </v-row>
                        

                    </v-col>

                   <!--  <v-col lg="6" class="pt-0">
                         <v-row>
                            <h4 class="mt-6 ml-6"> Presentaciones     </h4>
                                <v-spacer> </v-spacer>
                                
                        </v-row>

                        <v-row>
                            <v-col class="pb-0">
                                <v-divider></v-divider>
                            </v-col>
                        </v-row>
                        <v-list>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-btn color="info" fab><i class="fas fa-clock" style="font-size:18px"></i></v-btn>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-list-item-title ><b style="color: blue">{{itemsProductivity.PrfTurnPackingEurHeaderCE[0].TrnDate}}</b></v-list-item-title>
                                    <v-list-item-subtitle >Fecha</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-btn color="warning" fab><i style="font-size:18px" class="fas fa-user-clock"></i></v-btn>
                                </v-list-item-avatar>
                                
                                <v-list-item-content>
                                    <v-list-item-title >{{itemsProductivity.PrfTurnPackingEurHeaderCE[0].TypeTurnName}} </v-list-item-title>
                                    <v-list-item-subtitle >Turno</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            

                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-btn color="warning" fab><i style="font-size:18px" class="fas fa-user-clock"></i></v-btn>
                                </v-list-item-avatar>
                                
                                <v-list-item-content>
                                    <v-list-item-title >{{itemsProductivity.PrfTurnPackingEurHeaderCE[0].TrnDateBegin}} - {{itemsProductivity.PrfTurnPackingEurHeaderCE[0].TrnDateEnd}}</v-list-item-title>
                                    <v-list-item-subtitle >Hora Inicio / Hora Fin</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            
                        </v-list>
                    </v-col> -->

                    <v-col lg="12" class="pt-0">
                         <v-row>
                            <h4 class="mt-6 ml-6"> Empaque     </h4>
                                <v-spacer> </v-spacer>
                                
                        </v-row>

                        <v-row>
                            <v-col class="pb-0">
                                <v-divider></v-divider>
                            </v-col>
                        </v-row>

                        <v-col cols="12" class="mt-5">
                            <v-data-table
                                dense
                                :items="itemPacking"
                                :headers="itemPackingHeader"
                                hide-default-footer
                            >

                            </v-data-table>
                            
                            
                        </v-col>
                        <v-row>
                            <v-col cols="6">
                                <b>Total Cajas: {{sumPackingBoxes}}</b>
                            </v-col>
                            <v-col cols="6">
                                <b>Total KG: {{sumPackingKG}}</b>
                            </v-col>
                        </v-row>
                       
                    </v-col>

                    <v-col lg="12" class="pt-0">
                         <v-row>
                            <h4 class="mt-6 ml-6"> Personal Transferido     </h4>
                                <v-spacer> </v-spacer>
                                
                        </v-row>

                        <v-row>
                            <v-col class="pb-0">
                                <v-divider></v-divider>
                            </v-col>
                        </v-row>

                        <v-col cols="12" class="mt-5">
                            <v-data-table
                                dense
                                :items="itemTransfer"
                                :headers="itemTransferHeader"
                                hide-default-footer
                            >

                            </v-data-table>
                            
                            
                        </v-col>
                       
                    </v-col>


                </v-row>

            </v-container>
        </v-card>

    </div>

</template>

<script>
	
	export default {
        props:{
            itemsProductivity: null
        },

		data() {
            return {
                itemWorkSchedule: {},
                itemWorkers : {},
                itemSupplying: [],
                itemPacking: [],
                itemSupplyingHeader: [
                    {text: "Zona", value: "VrtName"},
                    {text: "Variedad", value: "TypeZonLocationName"},
                    {text: "Jabas Abast.", value: "AbasQuantityJabas"},
                    {text: "KG Abast.", value: "AbasQuantityKG"},
                    {text: "KG Descarte.", value: "AbasQuantityDiscardKG"},
                    {text: "% Descarte.", value: "AbasQuantityDiscardPerce"},
                    {text: "KG Merma.", value: "AbasQuantityDecreaseKG"},
                    {text: "% Merma.", value: "AbasQuantityDiscardKGPerce"},
                ],

                itemPackingHeader: [
                    {text: "Presentación", value: "PldNumInSale"},
                    {text: "Cant. Cajas", value: "PldQuantityBoxes"},
                    {text: "KG Empacados.", value: "PldWeight"},
                ],

                sumAbasKG: 0,
                sumAbasJabas: 0,

                sumPackingBoxes: 0,
                sumPackingKG: 0,

                itemTransfer: [],
                itemTransferHeader: [
                    {text: "Persona", value: "AgdWorkerName"},
                    {text: "Docuemento", value: "PrsDocumentNumber"},
                    {text: "Hora Inicio", value: "AgdBeginDate"},
                    {text: "Hora Fin", value: "AgdEndDate"},
                    {text: "H. Trabajadas", value: "AgdHoursWorkedReal"},
                ],


            }
            
		},
		methods: {
		},
		created() {

            console.log("itemsProductivity", this.itemsProductivity);

            if(this.itemsProductivity.PrfTurnPackingEurHeaderCE.length > 0){

                this.itemsProductivity.PrfTurnPackingEurHeaderCE.forEach(element => {
                    element.TrnDate = this.$moment(element.TrnDate).format(this.$const.FormatDateDB);
                    element.TrnDateBegin = this.$moment(element.TrnDateBegin).format(this.$const.FormatTimeDB);
                    element.TrnDateEnd = this.$moment(element.TrnDateEnd).format(this.$const.FormatTimeDB);
                });

                this.itemWorkSchedule = this.itemsProductivity.PrfTurnPackingEurHeaderCE[0]
            }

            if(this.itemsProductivity.PrfTurnPackingEurWorkerCE.length > 0){
                this.itemWorkers = this.itemsProductivity.PrfTurnPackingEurWorkerCE[0]
            }

            this.sumAbasKG = 0
            this.sumAbasJabas =  0

            this.itemSupplying = this.itemsProductivity.PrfTurnPackingEurSupplyingCE
            this.itemSupplying.forEach(element => {
                this.sumAbasKG += parseInt(element.AbasQuantityKG)
                this.sumAbasJabas += parseInt(element.AbasQuantityJabas)
            });

            

            this.sumPackingBoxes = 0
            this.sumPackingKG = 0

            this.itemPacking = this.itemsProductivity.PrfTurnPackingEurKGCE
            this.itemPacking.forEach(element => {
                this.sumPackingBoxes += parseInt(element.PldQuantityBoxes)
                this.sumPackingKG += parseInt(element.PldWeight)
            });


            if(this.itemsProductivity.PrfTurnWorkerTransferCE.length > 0){

                this.itemsProductivity.PrfTurnWorkerTransferCE.forEach(element => {
                    element.AgdBeginDate = this.$moment(element.AgdBeginDate).format(this.$const.FormatDateTimeView);
                    element.AgdEndDate = this.$moment(element.AgdEndDate).format(this.$const.FormatDateTimeDB);
                    
                    
                });

                this.itemTransfer = this.itemsProductivity.PrfTurnWorkerTransferCE
            }

            




            console.log("recibineod datos", this.itemWorkers);
        }
	};
</script>

<style>
</style>