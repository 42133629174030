<template>
	<v-card dense>
		<v-row style="margin: auto;">
			<v-col cols="12" lg="2" md="4" sm="12">
						<s-select-definition
							label="Nave"
							:def="1342"
							v-model="TypeShip"
						></s-select-definition>
					</v-col>

			<v-col cols="6">
				<b
					><s-select-definition :def="def"  :dgr="dgr" label="Area" v-model="TypeAre">
					</s-select-definition>
				</b>
			</v-col>
			<!-- v-if="TypeAre == 2" -->
			
			<v-col lg="4" >
				<s-select-activity-area
					label="Actividad"
					:typeArea="TypeAre"
					:typeShip="TypeShip"
					v-model="AtaID"
				></s-select-activity-area>
			</v-col>
			<v-col cols="6">
				<v-btn color="info" @click="save()">
					Enviar
				</v-btn>
				<span style="margin-left: 10px;">
					<v-btn color="warning" @click="closeModal()">
						Close
					</v-btn>
				</span>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
	import _sTurnWorKerervice from "../../../services/FreshProduction/PrfAssignTurnWorKerDetailService";
	import sSelectActivityArea from "../../../components/FreshProduction/Turn/sSelectActivityArea.vue";

	export default {
		props: {
			item: {
				required: true,
				default: null,
			},
		},
		components: { sSelectActivityArea },

		data: () => ({
			TypeAre: 0,
			AgdIDOLD: 0,
			AtaID: 0,
			TypeShip: 0,
			dgr: 40,
			def: 1281,
		}),

		watch: {
			TypeShip(){
				if(this.TypeShip == 1){
					this.def = 1281
					this.dgr = 40
				}

				if(this.TypeShip == 2){
					this.def = 1348
					this.dgr=41
					
				}
			}
		},

		created() {
			this.AgdIDOLD = this.item.AgdID;
			console.log("item", this.item);
		},

		methods: {
			closeModal() {
				this.$emit("close");
			},
			save() {
				/* if (this.TypeAre == 2) {
					this.item.AtaID = this.AtaID;
				} else {
					this.item.AtaID = null;
				} */
				this.item.TypeShip = this.TypeShip
				this.item.AtaID = this.AtaID;
				this.item.TypeAre = this.TypeAre;
				this.item.AgdIDOLD = this.AgdIDOLD;
				this.item.AgdTransfer = "Si";
				this.item.AgtStatus = 1;
				this.item.SecStatus = 1;
				this.item.UsrCreateID = this.$fun.getUserID();
				this.item.AgdID = 0;

				console.log("Guardar",this.item);

				this.$fun
					.alert("Esta seguro de transferir ? ", "question")
					.then(val => {
						if (val.value) {
							_sTurnWorKerervice
								.save(this.item, this.$fun.getUserID())
								.then(r => {
									if (r.status == 200) {
										this.$fun.alert(
											"Tranferido correctamente",
											"success",
										);
										this.closeModal();
									}

									//this.$refs.gridAssistenceP.refresh();
								});
						}
					});
			},
		},
	};
</script>
