<template>
  <div>
    <v-card>
      <v-container>
        <v-row>
          <h3 class="mt-3 ml-6">REPORTE CALIBRADORA CONVENCIONAL</h3>
          <v-spacer> </v-spacer>
          <v-btn class="mt-3 mr-3" small fab color="default"
            ><i
              style="font-size: 20px"
              color="error"
              class="fas fa-file-pdf"
            ></i
          ></v-btn>
          <v-btn
            @click="$emit('close')"
            class="mt-3 mr-3"
            small
            fab
            color="error"
            ><i style="font-size: 16px" class="fas fa-times"></i
          ></v-btn>
        </v-row>

        <v-row>
          <v-col class="pb-0">
            <v-divider></v-divider>
          </v-col>
        </v-row>

        <v-row>
          <v-col lg="6" class="pt-0">
            <v-row>
              <h4 class="mt-6 ml-6">Horario de trabajo</h4>
              <v-spacer> </v-spacer>
            </v-row>

            <v-row>
              <v-col class="pb-0">
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <v-list>
              <v-list-item>
                <v-list-item-avatar>
                  <v-btn color="info" fab
                    ><i class="fas fa-clock" style="font-size: 18px"></i
                  ></v-btn>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                    ><b>{{ itemWorkSchedule.TrnDate }}</b></v-list-item-title
                  >
                  <v-list-item-subtitle>Fecha</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-avatar>
                  <v-btn color="warning" fab
                    ><i style="font-size: 18px" class="fas fa-user-clock"></i
                  ></v-btn>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                    ><b>{{ itemWorkSchedule.TypeTurnName }}</b>
                  </v-list-item-title>
                  <v-list-item-subtitle>Turno</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-avatar>
                  <v-btn color="warning" fab
                    ><i style="font-size: 18px" class="fas fa-user-clock"></i
                  ></v-btn>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                    ><b
                      >{{ itemWorkSchedule.TrnDateBegin }} -
                      {{ itemWorkSchedule.TrnDateEnd }} =
                      {{ itemWorkSchedule.TrnHoursWorkedAll }}</b
                    ></v-list-item-title
                  >
                  <v-list-item-subtitle
                    >Hora Inicio / Hora Fin</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-avatar>
                  <v-btn color="warning" fab
                    ><i style="font-size: 18px" class="fas fa-user-clock"></i
                  ></v-btn>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                    ><b>{{
                      itemWorkSchedule.TrnHoursWorkedDead
                    }}</b></v-list-item-title
                  >
                  <v-list-item-subtitle>Horas muertas</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-avatar>
                  <v-btn color="warning" fab
                    ><i style="font-size: 18px" class="fas fa-user-clock"></i
                  ></v-btn>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                    ><b
                      >{{ itemWorkSchedule.TrnHoursWorkedReal }}
                    </b></v-list-item-title
                  >
                  <v-list-item-subtitle>Horas Trabajadas</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>

          <v-col lg="6" class="pt-0">
            <v-row>
              <h4 class="mt-6 ml-6">Cantidad de Personal</h4>
              <v-spacer> </v-spacer>
            </v-row>

            <v-row>
              <v-col class="pb-0">
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <v-list>
              <v-list-item>
                <v-list-item-avatar>
                  <v-btn color="info" fab
                    ><i class="fas fa-clock" style="font-size: 18px"></i
                  ></v-btn>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                    ><b>{{ itemWorkers.AgdWorkerMan }}</b></v-list-item-title
                  >
                  <v-list-item-subtitle>Varones MOD</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-avatar>
                  <v-btn color="warning" fab
                    ><i style="font-size: 18px" class="fas fa-user-clock"></i
                  ></v-btn>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                    ><b>{{ itemWorkers.AgdWorkerWoman }} </b></v-list-item-title
                  >
                  <v-list-item-subtitle>Mujeres MOD</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-avatar>
                  <v-btn color="warning" fab
                    ><i style="font-size: 18px" class="fas fa-user-clock"></i
                  ></v-btn>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                    ><b>{{ itemWorkers.AgdWorkerAll }}</b></v-list-item-title
                  >
                  <v-list-item-subtitle>Total MOD</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>

          <v-col lg="12" class="pt-0">
            <v-row>
              <h4 class="mt-6 ml-6">Abastecimiento Calibradora</h4>
              <v-spacer> </v-spacer>
            </v-row>

            <v-row>
              <v-col class="pb-0">
                <v-divider></v-divider>
              </v-col>
            </v-row>

            <v-col cols="12" class="mt-5">
              <v-data-table
                dense
                :items="itemCalibrator"
                :headers="itemCalibratorHeader"
                hide-default-footer
              >
              </v-data-table>
            </v-col>
            <v-row>
              <v-col cols="6">
                <b>Total Jabas Abas.: {{ sumAbasCalibratorJabas }}</b>
              </v-col>
              <v-col cols="6">
                <b>Total KG Abast.: {{ sumAbasCalibratorKG }}</b>
              </v-col>
            </v-row>
          </v-col>

          <!--  <v-col lg="6" class="pt-0">
                         <v-row>
                            <h4 class="mt-6 ml-6"> Presentaciones     </h4>
                                <v-spacer> </v-spacer>
                                
                        </v-row>

                        <v-row>
                            <v-col class="pb-0">
                                <v-divider></v-divider>
                            </v-col>
                        </v-row>
                        <v-list>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-btn color="info" fab><i class="fas fa-clock" style="font-size:18px"></i></v-btn>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-list-item-title ><b style="color: blue">{{itemsProductivity.PrfTurnPackingEurHeaderCE[0].TrnDate}}</b></v-list-item-title>
                                    <v-list-item-subtitle >Fecha</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-btn color="warning" fab><i style="font-size:18px" class="fas fa-user-clock"></i></v-btn>
                                </v-list-item-avatar>
                                
                                <v-list-item-content>
                                    <v-list-item-title >{{itemsProductivity.PrfTurnPackingEurHeaderCE[0].TypeTurnName}} </v-list-item-title>
                                    <v-list-item-subtitle >Turno</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            

                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-btn color="warning" fab><i style="font-size:18px" class="fas fa-user-clock"></i></v-btn>
                                </v-list-item-avatar>
                                
                                <v-list-item-content>
                                    <v-list-item-title >{{itemsProductivity.PrfTurnPackingEurHeaderCE[0].TrnDateBegin}} - {{itemsProductivity.PrfTurnPackingEurHeaderCE[0].TrnDateEnd}}</v-list-item-title>
                                    <v-list-item-subtitle >Hora Inicio / Hora Fin</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            
                        </v-list>
                    </v-col> -->

          <v-col lg="12" class="pt-0">
            <v-row>
              <h4 class="mt-6 ml-6">KG Calibrados</h4>
              <v-spacer> </v-spacer>
            </v-row>

            <v-row>
              <v-col class="pb-0">
                <v-divider></v-divider>
              </v-col>
            </v-row>

            <v-col cols="12" class="mt-5">
              <v-data-table
                dense
                :items="itemCalibratorCaliber"
                :headers="itemCalibratorCaliberHeader"
                hide-default-footer
                group-by="TypeDestinyName"
              >
              </v-data-table>
            </v-col>
            <v-row>
              <v-col cols="12">
                <b>Total KG Calibrados.: {{ sumAbasCalibratorCaliberKG }}</b>
              </v-col>
            </v-row>
          </v-col>

          <v-col lg="12" class="pt-0">
            <v-row>
              <h4 class="mt-6 ml-6">Personal Transferido</h4>
              <v-spacer> </v-spacer>
            </v-row>

            <v-row>
              <v-col class="pb-0">
                <v-divider></v-divider>
              </v-col>
            </v-row>

            <v-col cols="12" class="mt-5">
              <v-data-table
                dense
                :items="itemTransfer"
                :headers="itemTransferHeader"
                hide-default-footer
              >
              </v-data-table>
            </v-col>
          </v-col>


          <v-col lg="12" class="pt-0">
            <v-row>
              <h4 class="mt-6 ml-6">Calibrado Total</h4>
              <v-spacer> </v-spacer>
            </v-row>
            <v-row>
              <v-col class="pb-0">
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <v-col cols="12" class="mt-5">
              <v-data-table
                dense
                :items="itemCalibratorAll"
                :headers="itemCalibratorAllHeader"  
                hide-default-footer
              >
                <template v-slot:item.RcfDateCalibrated="{ item }">
                  {{ moment(item.RcfDateCalibrated).format('DD-MM-YYYY')}}
                </template>

                 <template v-slot:item.DateBegin="{ item }">
                  {{ moment(item.DateBegin).format('DD-MM-YYYY')}}
                </template>

              </v-data-table>
            </v-col>
          </v-col>

        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    itemsProductivity: null,
  },

  data() {
    return {
      itemCalibratorAll: [],
      itemWorkSchedule: {},
      itemWorkers: {},
      itemCalibrator: [],
      itemCalibratorCaliber: [],

      itemCalibratorAllHeader : [
        { text: "Fecha calibrado", value:"RcfDateCalibrated"} ,
        { text: "Fecha de Inicio", value : "DateBegin"},
        { text: "N° Jabas abastecidas", value : "AmountJabasAbast"},
        { text: "N° Jabas restantes", value : "RemainingCrates"},
        { text: "Hora Inicio", value : "StartTime" },
        { text: "Hora Final", value : "FinalHour"},
        // { text:"Certificación" , value : "lll"},
        { text: "Variedad", value: "VrtName"},  
      ],

      itemCalibratorHeader: [
        { text: "Variedad", value: "VrtName" },
        { text: "Jabas Abastecidas", value: "CalQuantityJabas" },
        { text: "KG Abast.", value: "CalWeightAbast" },
        { text: "KG Descarte.", value: "CalDiscartAbast" },
        { text: "KG %.", value: "CalDiscartAbastPerce" },
      ],

      itemCalibratorCaliberHeader: [
        { text: "Destino", value: "TypeDestinyName" },
        { text: "Variedad", value: "VrtName" },
        { text: "Calibre", value: "TypeCaliberName" },
        { text: "KG. Calibrados", value: "LppWeightAverageLot" },
      ],

      sumAbasCalibratorKG: 0,
      sumAbasCalibratorJabas: 0,

      sumAbasCalibratorCaliberKG: 0,

      itemTransfer: [],
      itemTransferHeader: [
        { text: "Persona", value: "AgdWorkerName" },
        { text: "Docuemento", value: "PrsDocumentNumber" },
        { text: "Hora Inicio", value: "AgdBeginDate" },
        { text: "Hora Fin", value: "AgdEndDate" },
        { text: "H. Trabajadas", value: "AgdHoursWorkedReal" },
      ],
    };
  },
  methods: {},
  created() {
    console.log("itemsProductivity", this.itemsProductivity);

    if (this.itemsProductivity.PrfTurnPackingEurHeaderCE.length > 0) {
      this.itemsProductivity.PrfTurnPackingEurHeaderCE.forEach((element) => {
        element.TrnDate = this.$moment(element.TrnDate).format(
          this.$const.FormatDateDB
        );
        element.TrnDateBegin = this.$moment(element.TrnDateBegin).format(
          this.$const.FormatTimeDB
        );
        element.TrnDateEnd = this.$moment(element.TrnDateEnd).format(
          this.$const.FormatTimeDB
        );
      });

      this.itemWorkSchedule =
        this.itemsProductivity.PrfTurnPackingEurHeaderCE[0];
    }

    if (this.itemsProductivity.PrfTurnPackingEurWorkerCE.length > 0) {
      this.itemWorkers = this.itemsProductivity.PrfTurnPackingEurWorkerCE[0];
    }

    this.sumAbasCalibratorKG = 0;
    this.sumAbasCalibratorJabas = 0;

    this.itemCalibratorAll = this.itemsProductivity.PrfTurnCalibratedSupplyAllCE // david
    this.itemCalibrator = this.itemsProductivity.PrfTurnCalibratedSupplyCE;
    this.itemCalibrator.forEach((element) => {
      this.sumAbasCalibratorKG += parseFloat(element.CalWeightAbast);
      this.sumAbasCalibratorJabas += parseInt(element.CalQuantityJabas);
    });

    this.sumAbasCalibratorCaliberKG = 0;

    this.itemCalibratorCaliber =
      this.itemsProductivity.PrfTurnCalibratedSupplyCaliberCE;
    this.itemCalibratorCaliber.forEach((element) => {
      this.sumAbasCalibratorCaliberKG += parseFloat(
        element.LppWeightAverageLot
      );
    });

    if (this.itemsProductivity.PrfTurnWorkerTransferCE.length > 0) {
      this.itemsProductivity.PrfTurnWorkerTransferCE.forEach((element) => {
        element.AgdBeginDate = this.$moment(element.AgdBeginDate).format(
          this.$const.FormatDateTimeView
        );
        element.AgdEndDate = this.$moment(element.AgdEndDate).format(
          this.$const.FormatDateTimeDB
        );
      });

      this.itemTransfer = this.itemsProductivity.PrfTurnWorkerTransferCE;
    }

    if(this.itemsProductivity.PrfTurnCalibratedSupplyAllCE.length > 0) {
      this.itemCalibratorAll = this.itemsProductivity.PrfTurnCalibratedSupplyAllCE;
    }

  },
};
</script>

<style>
</style>