<template>
	<v-card>
		<v-container>
			<v-row>
				<h2 class="mt-6 ml-3">
					{{
						"Paradas"
					}}
				</h2>
				<v-spacer></v-spacer>
				<v-btn
					rounded
					class="mt-3 mr-3"
					@click="closeDialogReceptionStop()"
					small
					fab
					color="error"
					><i style="font-size: 16px" class="fas fa-times"></i
				></v-btn>
			</v-row>
			<v-row justify="center" style="margin:auto" class="mt-5">
				<v-row >
						<v-col cols="6" class="pb-0">
							<s-date
								type="datetime"
								v-model="RfsDateBegin"
								label="Hora Inicio"
							></s-date>
						</v-col>
						<v-col cols="6" class="pb-0">
							<s-date
								type="datetime"
								v-model="RfsDateEnd"
								label="Hora Fin"
							></s-date>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" class="pb-0 mt-0">
							<s-select
								label="Motivo"
								v-model="reason"
								:items="itemsReason"
								item-value="RstID"
								item-text="RstDescription"
							/>
						</v-col>
						<v-col cols="12" class="pb-0 mt-0">
							<s-select-activity-area
								label="Cuadrilla"
								:typeArea="1"
								:typeShip="1"
								v-model="AtaID"
							></s-select-activity-area>
						</v-col>
					</v-row>
				
				<v-col lg="4">
					<v-btn
						class="mt-4"
						color="info"
						
						rounded
						small
						@click="save()"
					>
						Guardar
					</v-btn>
				</v-col>
			</v-row>
			<!-- <v-row>
				<v-col cols="12">
					<v-data-table></v-data-table>
				</v-col>
			</v-row> -->
		</v-container>
	</v-card>
</template>

<script>
	import sSelectActivityArea from "../../../components/FreshProduction/Turn/sSelectActivityArea.vue";
	import sReceptionFreshUnloadService from "../../../services/FreshProduction/ReceptionFreshUnloadService.js";

	export default {
		components: {
			sSelectActivityArea,
		},

		props: {
			filter: null,
			itemsReason: null,
			AtaID: null
			
		},

		data() {
			return {
				RfsDateBegin: null,
				RfsDateEnd: null,
				reason: 0
			}
		},

		methods: {
			closeDialogReceptionStop() {
				this.$emit("closeDialogReceptionStop");
			},

			save(){
				let item ={}
				item.RfsID = 0
				item.AtaID = this.AtaID
				item.RstID = this.reason
				item.RfsDateBegin = this.RfsDateBegin
				item.RfsDateEnd = this.RfsDateEnd
				item.SecStatus = 1
				item.UsrCreateID = this.$fun.getUserID();


				if(item.RfsReason == 0){
					this.$fun.alert("Seleccione motivo", "warning")
					return
				}

				if(item.AtaID == 0){
					this.$fun.alert("Seleccione una cuadrilla", "warning")
					return
				}

				if(item.RfsDateBegin == null){
					this.$fun.alert("Seleccione hora de inicio", "warning")
					return
				}

				if(item.RfsDateEnd == null){
					this.$fun.alert("Seleccione hora de fin", "warning")
					return
				}

				this.$fun.alert("¿Seguro de guardar Parada?", "question").then(val => {
					if(val.value){
						sReceptionFreshUnloadService.stopUnload(item, this.$fun.getUserID()).then(resp => {
							if (resp.status == 200) {
									this.$fun.alert("Guarado correctamente", "success");
									this.closeDialogReceptionStop()
							}
						})
					}
				})
			
			},
		},

		created () {

			


			console.log('filter ', this.itemsReason);;
		},
	};
</script>
