<template>
	<v-container class="pt-0">
		<v-dialog v-model="showNewTurn" v-if="showNewTurn" width="300">
			<v-card>
				<v-col>
					<v-row>
						<h2 class="mt-6 ml-3">Nuevo Turno</h2>
						<v-spacer> </v-spacer>
						<v-btn
							@click="showNewTurn = false"
							class="mt-3 mr-3"
							small
							fab
							color="error"
							><i style="font-size: 16px" class="fas fa-times"></i
						></v-btn>
					</v-row>

					<v-row class="pt">
						<v-col class="pt-6">
							<s-text
								label="Nombre"
								v-model="item.TnrObservation"
								outlined
								placeholder="dd/MM/yyyy"
							></s-text>
						</v-col>
					</v-row>
					<v-row>
						<v-col class="pt-0">
							<v-btn color="success" block @click="save()"
								>Crear</v-btn
							>
						</v-col>
					</v-row>
				</v-col>
			</v-card>
		</v-dialog>

		<v-dialog
			persistent
			width="1000"
			v-model="showProductivity"
			v-if="showProductivity"
		>
			<turn-productivity
				:productivity="productivity"
				@closeshowProductivity="showProductivity = false"
			></turn-productivity>
		</v-dialog>

		<v-dialog
			persistent
			width="1000"
			v-model="showProductivityEuropa"
			v-if="showProductivityEuropa"
		>
			<turn-productivity-europa
				:itemsProductivity="productivity"
				@close="showProductivityEuropa = false"
			></turn-productivity-europa>
		</v-dialog>

		<v-dialog
			persistent
			width="1000"
			v-model="showProductivityCalibrator"
			v-if="showProductivityCalibrator"
		>
			<turn-productivity-calibrator
				:itemsProductivity="productivity"
				@close="showProductivityCalibrator = false"
			></turn-productivity-calibrator>
		</v-dialog>

		<v-dialog
			persistent
			width="1000"
			v-model="showProductivityCalibratorOrg"
			v-if="showProductivityCalibratorOrg"
		>
			<turn-productivity-calibrator-org
				:itemsProductivity="productivity"
				@close="showProductivityCalibratorOrg = false"
			></turn-productivity-calibrator-org>
		</v-dialog>









		<s-toolbar label="Turnos">
			<slot>
				<v-btn
					v-if="selected.TrnStatus == 1 && selectedAll.length > 0"
					class="mr-4"
					small
					color="warning"
					outlined
					@click="
						dialog = true;
						RstStatus = 2;
					"
					><i
						style="font-size: 24px; margin-right: 6px"
						class="fas fa-plus-circle"
					></i>
					Parada
				</v-btn>
			</slot>
			<v-divider vertical></v-divider>
			<slot>
				<v-btn
					small
					color="success"
					outlined
					@click="reportProductivity(selected)"
					><i style="font-size: 16px" class="fas fa-file-pdf"></i
				></v-btn>
			</slot>
		</s-toolbar>
		<v-card style="margin-bottom: 5px" class="elevation-0">
			<v-col>
				<v-row justify="center" class="pt-1" style="margin: auto">
					<v-col cols="12" lg="2" md="4" sm="12" class="pt-0">
						<s-select-definition
							label="Nave"
							:def="1342"
							v-model="item.TypeShip"
						></s-select-definition>
					</v-col>

					<v-col cols="12" lg="2" md="4" sm="12" class="pt-0">
						<s-select-definition
							label="Turno"
							:def="1278"
							v-model="item.TypeTurn"
						></s-select-definition>
					</v-col>
					<v-col cols="12" lg="3" md="4" sm="12" class="pt-0">
						<s-select-definition
							label="Area"
							v-model="item.TypeAre"
							:def="def"
							:dgr="dgr"
							@input="inputTypeArea($event)"
						></s-select-definition>
					</v-col>
					<v-col cols="12" lg="2" md="6" sm="12" class="pb-0">
						<v-btn
							block
							color="success"
							@click="showNewTurn = true"
							rounded
							:disabled="disabledBtnTurn"
						>
							<i
								style="font-size: 18px; margin-right: 6px"
								class="far fa-play-circle"
							></i>
							Iniciar Turno
						</v-btn>
					</v-col>

					<v-col
						cols="12"
						lg="2"
						md="6"
						class="pb-0"
						v-if="selected.TrnStatus == 1 && selectedAll.length > 0"
					>
						<v-btn
							rounded
							block
							:color="
								selected.PrfTurnStopCE ? 'default' : 'warning'
							"
							@click="anulateStop(selected.PrfTurnStopCE)"
						>
							<i
								style="font-size: 24px; margin-right: 6px"
								:class="{
									'far fa-play-circle':
										selected.PrfTurnStopCE != null,
									'far fa-pause-circle':
										selected.PrfTurnStopCE == null,
								}"
							></i>
							{{ selected.PrfTurnStopCE ? "Reanudar" : "Pausar" }}
						</v-btn>
					</v-col>

					<!-- <v-col cols="12" lg="2" class=" pb-0" >
						<v-btn
							block
							outlined rounded
							color="warning"
							@click="
								dialog = true;
								RstStatus = 2;
							"
						>
							<i
								style="font-size:24px;margin-right:6px;"
								class="fas fa-plus-circle"
							></i
							>Parada
						</v-btn>
					</v-col> -->
				</v-row>
			</v-col>
		</v-card>

		<div>
			<v-row>
				<v-col cols="12" :lg="selectedAll.length > 0 ? 6: 12" md="12" class="pb-0">
					<s-crud
						:config="configTurn"
						:filter="filter"
						no-search
						title="Registro de Turnos"
						ref="gridTurn"
						no-full
						height="120"
						
						@rowSelected="rowSelected($event)"
					>
						<template v-slot:TrnStatus="{ row }">
							<v-btn
								x-small
								:color="
									row.TrnStatus == 1 ? 'error' : 'default'
								"
								:height="25"
								rounded
								style="margin: 10px 10px 10px 5px"
								@click="anulateTurn(row)"
								><i
									style="font-size: 16px; margin-right: 6px"
									class="far fa-stop-circle"
								></i>
								{{
									row.TrnStatus == 1
										? "Cerrar Turno"
										: "Terminado"
								}}
							</v-btn>
						</template>

						<template v-slot:options>
							<div>
								<v-btn
									v-if="$fun.isAdmin()"
									x-small 
									color="info" 
									@click="enabledTurn()"
								>	
									<i class="far fa-stop-circle"></i>Habilitar Turno
								</v-btn>
							</div>
						</template>

						

					</s-crud>
				</v-col>
				<v-col class="pb-0" :lg="selectedAll.length > 0 ? 6: 12" v-if="selectedAll.length > 0">
					<s-crud
						height="auto"
						title="Paradas"
						:filter="filterStop"
						:config="configTurnStop"
						ref="crudStop"
						no-full
						remove
					/>
				</v-col>
				<v-col cols="12" lg="12" md="12">
					<s-crud
						:singleRow="false"
						:config="configAssistence"
						:filter="filterAssistence"
						search-input
						remove
						deleteNoFunction
						@delete="deleteAll($event)"
						title="Asignaciones"
						ref="gridAssistenceP"
						height="auto"
						@rowSelected="rowSelectedAsstence($event)"
						:disabled="false"
						no-full
					>
						<template v-slot:filter>
							<v-col>
								<v-card
									flat
									:disabled="
										selected.TrnStatus == 1 ? false : true
									"
								>
									<v-row justify="center">
										<!-- v-if="filter.TypeAre == 2" -->
										<v-col cols="12" lg="6" md="6">
											<s-select-activity-area
												label="Actividad"
												:typeArea="filter.TypeAre"
												:typeShip="filter.TypeShip"
												v-model="AtaID"
											></s-select-activity-area>
										</v-col>
										<v-col cols="12" lg="6" md="6">
											<!-- <s-text
												label="Doc. Identidad"
												@keyupEnter="saveAssistence()"
												ref="txtDni"
												v-model="PrsDocumentNumber"
											></s-text> -->
											<s-scanner-qr
												:config="configScann"
												autofocus
												return-object 
												@onValue="saveAssistence($event)"
												:readonly="false"
												cameraIcon
												label="Doc. Identidad"
											></s-scanner-qr><!--@onValue="saveAssistence($event)" -->
										</v-col>
									</v-row>
								</v-card>
							</v-col>
						</template>
						<template v-slot:Transfer="{ row }">
							<v-btn
								:disabled="disabledBtnTransfer"
								x-small
								:color="'warning'"
								style="
									border-radius: 13px;
									margin: 10px 10px 10px 10px;
								"
								@click="clickTranfer(row)"
								>Tranferir
							</v-btn>
							<!-- <v-btn
								:disabled="disabledBtnTransfer"
								x-small
								:color="'error'"
								style="border-radius: 13px; margin: 10px 10px 10px 10px;"
								>Terminar
							</v-btn> -->
						</template>
						<template v-slot:Print="{ row }">
							<!-- <v-btn
								v-if="(filter.TypeAre == 2 || filter.TypeAre == 3) && selected.TypeShip  == 1"
								x-small
								dark
								:color="''"
								style="
									border-radius: 13px;
									margin: 10px 10px 10px 10px;
								"
								@click="clickPrint(row)"
								><v-icon>mdi-printer</v-icon>
							</v-btn> -->
						</template>

						<template v-slot:options>
							
						</template>

						<template v-slot:options>
							<div v-if="filter.TypeAre == 1 && selected.TrnStatus == 1" >
								<v-btn
									x-small 
									color="red" 
									@click="dialogReceptionStop = true"
								>	
									<i class="far fa-stop-circle"></i>Paradas
								</v-btn>

								<v-btn 
									color="primary" 
									x-small 
									@click="dialogUnload = true"
								><i class="far fa-play-circle"></i>
									Descarga Fruta</v-btn>

								<v-dialog persistent v-model="dialogUnload" v-if="dialogUnload" width="80%">
									<s-toolbar 
										color="primary"             
            							dark
										close
										@close="dialogUnload = false"></s-toolbar>
									<unload
										@closeModalUnload="dialogUnload = false"
										:item="AtaID"
										:itemul="item"
									></unload>
								</v-dialog>
							</div>
						</template>

					</s-crud>
				</v-col>
			</v-row>

			<v-dialog
				v-if="openDialog"
				v-model="openDialog"
				width="80%"
				persistent
			>
				<transfer
					:item="itemAssistence"
					@close="closeModal()"
				></transfer>
			</v-dialog>

			<v-dialog
				v-if="showModalPrint"
				v-model="showModalPrint"
				width="60%"
				persistent
			>
				<turn-print-label :item="selectedRow" :typeArea="item.TypeAre" @close="closePrint()"></turn-print-label>
			</v-dialog>
		</div>

		

		<v-dialog v-model="dialog" width="400" persistent>
			<v-card>
				<v-col>
					<v-row>
						<h2 class="mt-6 ml-3">
							{{
								RstStatus == 1 ? "Pausar Turno" : "Nueva Parada"
							}}
						</h2>
						<v-spacer></v-spacer>
						<v-btn
							rounded
							class="mt-3 mr-3"
							@click="dialog = false"
							small
							fab
							color="error"
							><i style="font-size: 16px" class="fas fa-times"></i
						></v-btn>
					</v-row>
				</v-col>
				<v-card-text class="pt-3">
					<v-row v-if="RstStatus == 2">
						<v-col cols="6" class="pb-0">
							<s-date
								type="datetime"
								v-model="TpsDateBegin"
								label="Hora Inicio"
							></s-date>
						</v-col>
						<v-col cols="6" class="pb-0">
							<s-date
								type="datetime"
								v-model="TpsDateEnd"
								label="Hora Fin"
							></s-date>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12">
							<s-select
								label="Motivo"
								v-model="reason"
								:items="itemsReason"
								item-value="RstID"
								item-text="RstDescription"
							/>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" class="pt-0">
							<v-btn
								color="primary"
								block
								@click="saveStop"
								rounded
								>Guardar</v-btn
							>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</v-dialog>

		<!-- dialog para parada de recepcion -->
		<v-dialog
			v-model="dialogReceptionStop"
			persistent
			width="500"
		>
			<reception-stop
				:itemsReason="itemsReason"
				:AtaID="AtaID"
				@closeDialogReceptionStop="closeDialogReceptionStop()"
			></reception-stop>
		</v-dialog>
		<!-- fin dialog -->
	</v-container>
</template>
<script>
	//Service
	import _sTurnService from "../../../services/FreshProduction/PrfTurnService";
	import _sTurnWorKerervice from "../../../services/FreshProduction/PrfAssignTurnWorKerDetailService";
	import sSelectActivityArea from "../../../components/FreshProduction/Turn/sSelectActivityArea.vue";
	import transfer from "./Transfer.vue";
	import _sPrfTurnReasonStop from "@/services/FreshProduction/PrfTurnStopReasonService";
	
	
	import _sPrfTurnStop from "@/services/FreshProduction/PrfTurnStopService";
	import TurnProductivity from "./TurnProductivity";
	import TurnPrintLabel from "./TurnPrintLabel";
	import ReceptionStop from "./ReceptionStop";


	//Reportes Productividad
	import TurnProductivityEuropa from "./TurnProductivityEuropa";
	import TurnProductivityCalibrator from "./TurnProductivityCalibrator";
	import TurnProductivityCalibratorOrg from "./TurnProductivityCalibratorOrg";
	import Unload from "@/views/FreshProduction/RawMaterialReception/Unload.vue";


	export default {
		components: { sSelectActivityArea, transfer, TurnProductivity, TurnPrintLabel, ReceptionStop, TurnProductivityEuropa, Unload, TurnProductivityCalibrator, TurnProductivityCalibratorOrg },

		data: () => ({
			configScann: {},
			dialogUnload:false,
			dialogReceptionStop: false,
			RstStatus: 0,
			itemsReason: [],
			reason: null,
			TpsDateBegin: null,
			TpsDateEnd: null,
			dialog: false,
			filterStop: { TrnID: 0 },
			configTurnStop: {
				model: {
					StoID: "ID",
					StoBeginDate: "datetime",
					StoEndDate: "datetime",
				},
				service: _sPrfTurnStop,
				headers: [
					{ text: "ID", value: "StoID" },
					{ text: "Motivo", value: "RstDescription" },
					{ text: "Fecha Inicio", value: "StoBeginDate" },
					{ text: "Fecha Fin", value: "StoEndDate" },
				],
			},
			productivity: null,
			showProductivity: false,
			showNewTurn: false,
			filter: { TypeAre: 0, TypeShip: 0 },
			filterAssistence: { TrnID: 0, AtaID: 2 },
			showModalPrint: false,
			item: {},
			selected: {},
			selectedAll: [],
			selectedAsistence: {},
			PrsDocumentNumber: null, //Doc. Identidad
			AtaID: 0, //Id de Actividad
			SeparatorQR: "",
			openDialog: false,
			itemAssistence: null,
			disabledBtnTransfer: true,
			disabledBtnTurn: false,
			quantityLabel: 1,
			itemLabel: [],
			valParamCaracterLabel: "",

			configTurn: {
				model: {
					TrnID: "ID",
					Actions: "",
					TrnStatus: "",
					TnrDateBegin : "datetime"
				},
				service: _sTurnService,
				headers: [
					{ text: "Id", value: "TrnID", sortable: false, width: "15" },
					{
						text: "Acciones",
						value: "TrnStatus",
						sortable: false,
						width: 70,
					},
					{ text: "Turno", value: "TypeTurnName", sortable: false },
					{ text: "Inicio Turno", value: "TnrDateBegin", sortable: false },
					
					/* {
						text: "Area",
						value: "TypeAreName",
						sortable: false,
						width: 200,
					}, */
				],
			},

			configAssistence: {
				model: {
					AgdID: "ID",
					Transfer: "",
					Print: "",
					AgdBeginDate: "datetime",
					AgdEndDate: "datetime",
					Print: "",
				},
				service: _sTurnWorKerervice,
				headers: [
					{ text: "Acciones", value: "Transfer", sortable: false },
					{ text: "Persona", value: "NtpNameFull", sortable: false },
					{ text: "Actividad", value: "AtaName", sortable: false },
					{ text: "Inicio", value: "AgdBeginDate", sortable: false },
					{ text: "Fin", value: "AgdEndDate", sortable: false },
					{ text: "Estado", value: "AtaName", sortable: false },
					{ text: "Print", value: "Print", sortable: false },
					//	{ text: "Cantidad", value: "AdwNumberLabel", sortable: false },
				],
			},

			dgr: 40,
			def: 1281,
			selectedRow: {},


			//reportes
			showProductivityEuropa: false,
			showProductivityCalibrator:false,
			showProductivityCalibratorOrg: false,
		}),

		watch: {
			selected() {
				_sTurnWorKerervice.codigoQR(this.$fun.getUserID()).then((r) => {
					if (r.data.length > 0) {
						this.SeparatorQR = r.data[0].PrmValue;
					}
				});
			},
			AtaID() {
				this.filterAssistence.AtaID = this.AtaID;
			},

			"item.TypeShip"() {
				if (this.item.TypeShip == 1) {
					this.def = 1281;
					this.dgr = 40;
				}

				if (this.item.TypeShip == 2) {
					this.def = 1348;
					this.dgr = 41;
				}
			},
		},

		created() {
			
			//this.loadParameter();
		},

		methods: {
			closeDialogReceptionStop()
			{
				this.dialogReceptionStop = false;
			},

			anulateStop(objStop) {
				if (objStop) {
					//CULMINAR PARADA
					objStop.StoEndDate = this.$fun.getUserID();
					this.$fun.alert("Seguro de Reanudar?", "question").then((v) => {
						if (v.value) {
							_sPrfTurnStop
								.save(objStop, this.$fun.getUserID())
								.then((r) => {
									this.selected.PrfTurnStopCE = null;
									this.$fun.alert("Turno reiniciado!", "success");
								});
						}
					});
				} else {
					this.dialog = true;
					this.RstStatus = 1;
				}
			},
			saveStop() {
				if (this.reason == null || this.reason == "") {
					this.$fun.alert("Ingrese el motivo", "warning");
					return;
				}

				if(this.selected.TnrDateBegin > this.TpsDateBegin){
					this.$fun.alert("Error, Tiempo Inicio de Para incorrecta", "warning")
					return;
				}
				this.$fun.alert("¿Seguro de guardar?", "question").then((val) => {
					if (val.value) {
						this.dialog = false;
						let tps = {
							TpsID: 0,
							TrnID: this.selected.TrnID,
							StoStatus: this.RstStatus,
							RstID: this.reason,
							StoBeginDate: this.TpsDateBegin,
							StoEndDate: this.TpsDateEnd,
							SecStatus: 1,
							UsrCreateID: this.$fun.getUserID(),
							UsrUpdateID: this.$fun.getUserID(),
						};
						_sPrfTurnStop
							.save(tps, this.$fun.getUserID())
							.then((resp) => {
								if (resp.status == 200) {
									if (this.RstStatus == 1)
										this.selected.PrfTurnStopCE = resp.data;
									this.$refs.crudStop.refresh();
									this.$fun.alert(
										"Guardado Correctamente",
										"success"
									);
									this.reason = "";
									this.TpsDateBegin = "";
									this.TpsDateEnd = "";
								}
							});
					}
				});
			},

			reportProductivity(selected) {

				if(this.selectedAll.length > 0){

				
					_sTurnService
						.receptionFresh(this.$fun.getUserID(), {TrnID: selected.TrnID, xFlag: this.xFlag})
						.then((r) => {
							this.productivity = r.data;

							if (selected.TypeArea == 1) { 
								this.showProductivity = true;
							}

							if (selected.TypeArea == 2) { 
								this.showProductivityEuropa = true
							}

							if (selected.TypeArea == 5) { 
								this.showProductivityCalibrator = true
							}

							if (selected.TypeArea == 6) { 
								this.showProductivityCalibratorOrg = true
							}
							
							
						});
				}

				
				
			},
			
			clickPrint(item) {

				
				this.selectedRow = item
				this.showModalPrint = true;
			},

			closeModal() {
				this.openDialog = false;
				this.$refs.gridAssistenceP.refresh();
			},

			closePrint() {
				this.showModalPrint = false;
				this.$refs.gridAssistenceP.refresh();
				/* this.itemLabel = [];
				this.quantityLabel = 0; */
			},

			rowSelected(item) {
				this.selectedAll = item;
				

				if (item.length > 0) {
					this.selected = item[0];
					
					if (this.selected.TrnStatus == 1) {
						this.disabledBtnTurn = true;
					}

					this.filterStop.TrnID = this.selected.TrnID;
					this.filterAssistence.TrnID = item[0].TrnID;

					this.PrsDocumentNumber = null;
					_sPrfTurnReasonStop
						.list(this.selected.TypeArea, this.selected.TypeShip, this.$fun.getUserID())
						.then((r) => {
							this.itemsReason = r.data;
						});

						//samir
					// this.$refs.txtDni.focus();

					if (this.selected.TrnStatus == 1) {
						this.disabledBtnTransfer = false;
					} else {
						this.disabledBtnTransfer = true;
					}
				} else {
					this.disabledBtnTurn = false;
				}
			},

			rowSelectedAsstence(item) {
				if (item.length > 0) {
					if (item !== undefined) {
						this.selectedAsistence = item[0];
						
					}
				}
			},

			inputTypeArea(val) {
				
				if (val !== undefined) {
					this.filterAssistence.TrnID = 0;

					this.AtaID = 0;
					//samir
					// this.$refs.txtDni.focus();
					this.filter.TypeAre = val;
					this.filter.TypeShip = this.item.TypeShip;

					if(val == 1){
						this.xFlag = 1
					}
					if(val == 2){
						this.xFlag = 2
					}
					if(val == 5){
						this.xFlag = 3
					}
					if(val == 6){
						this.xFlag = 4
					}
					
				}
			},

			/* inputFilter(val) {
					if (val !== 0) {
						this.item.TypeAre = val;
						this.AtaID = 0;
						this.PrsDocumentNumber = null;
						this.$refs.txtDni.focus();
						this.filterAssistence.TrnID = 0;
					}
				}, */

			save() {
				this.item.SecStatus = 1;
				this.item.UsrCreateID = this.$fun.getUserID();

				this.$fun
					.alert("Esta seguro de Guardar ? ", "question")
					.then((val) => {
						if (val.value) {
							_sTurnService
								.save(this.item, this.$fun.getUserID())
								.then((r) => {
									this.showNewTurn = false;
									this.$fun.alert(
										"Turno guardado correctamente",
										"success"
									);
									this.$refs.gridTurn.refresh();
								});
						}
					});
			},

			anulateTurn(item) {
				if (item.TrnStatus == 2) {
					this.$fun.alert("Turno ya terminado", "warning");
					return;
				}
				item.xFlag = 1
				item.TypeAre  =item.TypeArea
				item.SecStatus = 0;
				item.UsrCreateID = this.$fun.getUserID();

				

				this.$fun
					.alert("Esta seguro de terminar Turno ? ", "question")
					.then((val) => {
						if (val.value) {
							_sTurnService
								.save(item, this.$fun.getUserID())
								.then((r) => {
									this.$fun.alert(
										"Turno anulado correctamente",
										"success"
									);
									//this.$refs.gridAssistenceP.refresh();
									this.filterAssistence.TrnID = 0;
									this.$refs.gridTurn.refresh();
								});
						}
					});
			},

			saveAssistence(data) {
				this.PrsDocumentNumber = data;
				var DocDni, NtpID;

				if (this.selectedAll.length == 0) {
					this.$fun.alert("No existe Turno aperturado", "warning");
					return;
				}

				if (this.selected.TrnStatus == 2) {
					this.$fun.alert(
						"No se puede asginar, Turno ya terminado",
						"warning"
					);
					return;
				}

				if (this.AtaID == 0) {
					this.$fun.alert(
						"No se ha seleccionado una actividad",
						"warning"
					);
					return;
				}

				NtpID = this.PrsDocumentNumber.split(this.SeparatorQR, 6)[3];
				DocDni = this.PrsDocumentNumber.split(this.SeparatorQR, 6)[0];

				let assign = {
					AgdID: 0,
					NtpID: NtpID,
					PrsDocumentNumber: DocDni,
					TrnID: this.selected.TrnID,
					AtaID: this.AtaID,
					AgtStatus: 1,
					SecStatus: 1,
					UsrCreateID: this.$fun.getUserID(),
				};

				_sTurnWorKerervice
					.save(assign, this.$fun.getUserID())
					.then((val) => {
						this.$fun.alert("Guardado Correctamente", "success");
						this.PrsDocumentNumber = null;
						this.$refs.gridAssistenceP.refresh();
						//samir
						// this.$refs.txtDni.focus();
					});
			},

			clickTranfer(item) {
				this.openDialog = true;
				this.itemAssistence = item;
				this.itemAssistence.detail = this.selected;
			},

			deleteAll(item) {
				

				if (this.selected.TrnStatus == 2) {
					this.$fun.alert("Turno terminado", "warning");
					return;
				}

				this.$fun
					.alert("¿Seguro de eliminar registros ?", "question")
					.then((x) => {
						if (x.value) {
							item.forEach((element) => {
								element.SecStatus = 0;
								_sTurnWorKerervice
									.save(element, this.$fun.getUserID())
									.then((resp) => {
										if (resp.status == 200) {
											this.$refs.gridAssistenceP.refresh();
										}
									});
							});
						}
					});
			},

			enabledTurn(){
				
				if(this.selected.TrnStatus == 1){
					this.$fun.alert("El turno seleccionado se encuentra activo", "warning")
					return;
				}

				this.selected.xFlag = 2
				

				this.$fun
					.alert("¿Seguro de volver habilitar turno ?", "question")
					.then((x) => {
						if (x.value) {
								_sTurnService
									.save(this.selected, this.$fun.getUserID())
									.then((resp) => {
										if (resp.status == 200) {
											this.$fun.alert(
												"Turno habilitado correctamente",
												"success"
											);
											this.$refs.gridTurn.refresh();
											this.$refs.gridAssistenceP.refresh();
										}
									});
						}
					});
			},

			
		},
	};
</script>
